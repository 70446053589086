// for ie
// import 'babel-polyfill';
// bootstrap 4 latest iteration
// import 'bootstrap';

// pages
import './pages';
import 'slick-carousel';

// jquery cookie
// import 'js-cookie';
// window.fancySquareCookies = require('js-cookie');

// images loaded
// enque on pages if needed
// masonry
// enque on pages if needed

// bootstrap breakpoints https://github.com/Johann-S/bs-breakpoints
// window.bsBreakpoints = require('bs-breakpoints');

// axios
// window.axios = require('axios');
